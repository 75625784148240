import http from '@/util/http'
import {
  parseTime,
  downloadFile
} from '@/util/tool'

export default {
  data() {
    return {
      // 表格数据
      list: [],
      //分页查询参数：排序字段
      sorting: '',
      //分页查询参数：排序方式（desc，asc）
      sortType: '',
      // 页码
      pageIndex: 1,
      // 每页数据条数
      pageRows: 10,
      //默认分页大小
      pageSize:[
        10,20,30,40,50
      ],
      // 总数据条数
      total: 0,
      // 请求数据的url
      url: '',
      // 查询数据的参数
      params: {},
      // 待查询的对象
      query: {},
      // 等待时间
      time: 10,
      // 是否为新增类型的表单
      isAdd: false,
      // 导出的 Loading
      downloadLoading: false,
      uploadLoading: false,
      // 表格 Loading 属性
      loading: true,
      // 删除 Loading 属性
      delLoading: false,
      delAllLoading: false,
      // 弹窗属性
      dialog: false,
      // Form 表单
      form: {},
      // 重置表单
      resetForm: {},
      //文件上传用列表
      fileList:[],
      // 标题
      title: ''
    }
  },
  methods: {
    parseTime,
    downloadFile,
    //获取ApiResult分页
    init() {
      if (!this.beforeInit()) {
        return
      }
      this.loading = true
      // 请求数据
      http.post(this.url + '/listByPage', this.getQueryParam()).then(response => {
        this.total = response.data.counts
        this.list = response.data.rows
        // time 毫秒后显示表格
        setTimeout(() => {
          this.loading = false
        }, this.time)
      }).catch(err => {
        this.loading = false
      })
    },
    beforeInit() {
      return true
    },
    getQueryParam: function() {
      return {
        pageIndex: this.pageIndex,
        pageRows: this.pageRows,
        sorting: this.sorting,
        sortType: this.sortType,
        ...this.query,
        ...this.params
      }
    },
    // 改变页码
    pageChange(e) {
      this.pageIndex = e
      this.init()
    },
    // 改变每页显示数
    sizeChange(e) {
      this.pageIndex = 1
      this.pageRows = e
      this.init()
    },
    pageChangeOne(e){
      if (e === 1) {
        this.query.pageIndex -= 1;
      } else if (e === 2){
        this.query.pageIndex += 1;
      }
      this.init()
    },
    // 预防删除第二页最后一条数据时，或者多选删除第二页的数据时，页码错误导致请求无数据
    dleChangePage(pageRows) {
      if (pageRows === undefined) {
        pageRows = 1
      }
      if (this.list.length === pageRows && this.pageIndex !== 0) {
        this.pageIndex = this.pageIndex - 1
      }
    },
    // 查询方法
    toQuery() {
      this.pageIndex = 1
      this.init()
    },
    flushQuery(){
      this.query = {}
      this.pageIndex = 1
      this.init()
    },
    notify(title, type) {
      this.$notify({
        title: title,
        type: type,
        duration: 2500
      })
    },
    /**
     * 删除前可以调用 beforeDelMethod 做一些操作
     */
    beforeDelMethod() {
      return true
    },
    /**
     * 通用的删除
     */
    delMethod(id) {
      if (!this.beforeDelMethod()) {
        return
      }
      this.$confirm('你确定删除选中的数据吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(()=>{
        this.delLoading = true
        http.post(this.url + '/deleteById?id='+id).then(response => {
          if (response.data.success){
            this.$notify({
              title: '删除成功',
              type: 'success',
              duration: 2500
            })
            this.delLoading = false
            // this.$refs[id].doClose()
            this.dleChangePage()
            this.afterDelMethod()
            this.init()
          }else {
            this.notify(response.data.message,'error')
          }
        }).catch(() => {
          this.delLoading = false
          // this.$refs[id].doClose()
        })
      })

    },
    afterDelMethod() {

    },

    // /**
    //  * 多选删除提示
    //  */
    // beforeDelAllMethod() {
    //   this.$confirm('你确定删除选中的数据吗？', '提示', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     type: 'warning'
    //   }).then(() => {
    //     this.delAllMethod()
    //   })
    // },
    // /**
    //  * 多选删除
    //  */
    // delAllMethod() {
    //   this.delAllLoading = true
    //   const data = this.$refs.table.selection
    //   const ids = []
    //   for (let i = 0; i < list.length; i++) {
    //     ids.push(list[i].id)
    //   }
    //   this.crudMethod.delAll(ids).then(() => {
    //     this.delAllLoading = false
    //     this.dleChangePage(ids.length)
    //     this.init()
    //     this.$notify({
    //       title: '删除成功',
    //       type: 'success',
    //       duration: 2500
    //     })
    //   }).catch(() => {
    //     this.delAllLoading = false
    //   })
    // },

    /**
     * 显示新增弹窗前可以调用该方法
     */
    beforeShowAddForm() {},
    /**
     * 显示新增弹窗
     */
    showAddFormDialog() {
      this.isAdd = true
      this.form = {}
      this.resetForm = JSON.parse(JSON.stringify(this.form))
      this.beforeShowAddForm()
      this.dialog = true
    },
    /**
     * 显示编辑弹窗前可以调用该方法
     */
    beforeShowEditForm(data) {},
    /**
     * 显示编辑弹窗
     */
    showEditFormDialog(data) {
      this.isAdd = false
      this.resetForm = JSON.parse(JSON.stringify(data))
      this.form = JSON.parse(JSON.stringify(data))
      this.beforeShowEditForm(data)
      this.dialog = true
    },
    /**
     * 新增方法
     */
    addMethod() {
      http.post(this.url + '/add',this.form).then(response => {
        if (response.data.success){
          this.$notify({
            title: response.data.data||'新增成功',
            type: 'success',
            duration: 2500
          })
          this.loading = false
          this.afterAddMethod()
          // this.cancel()
          this.init()
        }else {
          this.$notify({
            title: response.data.message||'新增失败',
            type: 'error',
            duration: 2500
          })
        }
      }).catch(() => {
        this.loading = false
        this.afterAddErrorMethod()
      })
    },
    /**
     * 新增后可以调用该方法
     */
    afterAddMethod() {
      this.dialog = false
    },
    /**
     * 新增失败后调用该方法
     */
    afterAddErrorMethod() {},
    /**
     * 通用的编辑方法
     */
    editMethod() {
      http.post(this.url + '/edit',this.form).then(response => {
        if (response.data.success){
          this.$notify({
            title: '编辑成功',
            type: 'success',
            duration: 2500
          })
          this.loading = false
          this.afterEditMethod()
          // this.cancel()
          this.init()
        }else {
          this.$notify({
            title: response.data.message||'修改失败',
            type: 'error',
            duration: 2500
          })
        }
      }).catch(() => {
        this.loading = false
      })
    },
    /**
     * 编辑后可以调用该方法
     */
    afterEditMethod() {
      this.dialog = false
    },
    /**
     * 提交前可以调用该方法
     */
    beforeSubmitMethod() {
      return true
    },
    /**
     * 提交
     */
    submitMethod() {
      if (!this.beforeSubmitMethod()) {
        return
      }
      if (this.$refs['form']) {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            this.loading = true
            if (this.isAdd) {
              this.addMethod()
            } else{
              this.editMethod()
            }
          }else {
            console.log('error submit!!');
            return false;
          }
        })
      }
    },
    //重置表单
    resetFormMethod(){
      this.form = JSON.parse(JSON.stringify(this.resetForm))
    },
    /**
     * 隐藏弹窗
     */
    cancel() {
      this.dialog = false
      if (this.$refs['form']) {
        this.$refs['form'].clearValidate()
        this.form = this.resetForm
      }
    },
    /**
     * 获取弹窗的标题
     */
    getFormTitle() {
      return this.isAdd ? `新增${this.title}` : `编辑${this.title}`
    },
    /**
     * 通用导出excel
     */
    downloadMethod(fullUrl,name) {
      let url = fullUrl
      if(!fullUrl){
        url = this.url + '/download'
      }
      this.beforeInit()
      this.downloadLoading = true
      http.download(url, this.params).then(result => {
        if (name){
          this.downloadFile(result.data, name,'xlsx')
        }else {
          this.downloadFile(result.data, this.title + '数据', 'xlsx')
        }
        this.downloadLoading = false
      }).catch(() => {
        this.downloadLoading = false
      })
    },
    // 上传文件成功回调
    uploadFileSuccess() {
      console.log('上传成功')
      this.fileList = []
      this.uploadLoading = false
    },
    // 上传文件失败回调
    uploadFileError() {
      console.log('上传失败')
      this.fileList = []
      this.uploadLoading = false
      this.$notify({
        title: '上传失败',
        type: 'error',
        duration: 2500
      })
    },
    //上传文件并下载返回结果
    doUploadAndDownLoad(param){
      this.beforeInit()
      this.uploadLoading = true
      const fileObj = param.file
      const formData = new FormData()
      formData.append('file', fileObj)
      http.uploadAndDown(this.url + '/importTemplate',formData)
      .then(res=>{
        if (!res.success){
          param.onSuccess(res)
          this.downloadFile(res.data, this.title + '导入结果', 'xlsx')
        }else {
          param.onError(res)
        }
      })

    }
  }
}
