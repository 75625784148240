<template>
  <div>

    <div >
      <el-descriptions title="业务状态"  border >
        <el-descriptions-item label="申请流水号">{{ log.applyNo }}</el-descriptions-item>
        <el-descriptions-item label="申请状态">
          <span v-if="log.status===0">待审核</span>
          <span v-if="log.status===1">已通过审核</span>
          <span v-if="log.status===2">已通过金融平台审核</span>
          <span v-if="log.status===3">金融平台已放款</span>
          <span v-if="log.status===-1">已拒绝</span>
          <span v-if="log.status===-2">未通过金融平台审核</span>
          <span v-if="log.status===-3">放款失败</span>
        </el-descriptions-item>
        <el-descriptions-item label="关联项目">{{ log.acceptName }}</el-descriptions-item>
        <el-descriptions-item label="账单数量">{{ log.billList==null?'':log.billList.length }}</el-descriptions-item>
        <el-descriptions-item label="申请金额(元)">{{ log.applyAmount }}</el-descriptions-item>
        <el-descriptions-item label="申请期限(天)">{{ log.timeLimit }}</el-descriptions-item>
        <el-descriptions-item label="放款方">
          <span v-if="log.platform===1">三峡</span>
        </el-descriptions-item>
        <el-descriptions-item label="申请时间">{{ log.createTime }}</el-descriptions-item>
        <el-descriptions-item label="反馈">{{ log.reason }}</el-descriptions-item>
      </el-descriptions>
    </div>

    <el-descriptions title="公司信息"  border style="margin-top: 20px">
      <el-descriptions-item label="公司名称">{{ log.unitName }}</el-descriptions-item>
      <el-descriptions-item label="统一社会代码">{{ log.dutyNumber }}</el-descriptions-item>
      <el-descriptions-item label="公司法人">{{ log.legalPersonName }}</el-descriptions-item>
      <el-descriptions-item label="法人身份证">{{ log.legalPersonIdentity }}</el-descriptions-item>
      <el-descriptions-item label="法人手机号码">{{ log.legalPersonMobile }}</el-descriptions-item>
      <el-descriptions-item label="公司通讯地址">{{ log.unitAddress }}</el-descriptions-item>
      <el-descriptions-item label="公司注册地址">{{ log.unitRegisterAddress }}</el-descriptions-item>
      <el-descriptions-item label="收款账号开户行">{{ log.payeeBankName }}</el-descriptions-item>
      <el-descriptions-item label="收款账号">{{ log.payeeBankAccount }}</el-descriptions-item>
      <el-descriptions-item label="公司座机">{{ log.unitTel }}</el-descriptions-item>
    </el-descriptions>

    <div style="margin-top: 20px">
      <h3>账单信息</h3>
      <el-table :data='log.billList' max-height="250px">
        <el-table-column prop='receiptNo' label='回执单号' ></el-table-column>
        <el-table-column prop='startLocation' label='始发地' ></el-table-column>
        <el-table-column prop='endLocation' label='目的站' ></el-table-column>
        <el-table-column prop='goodsName' label='货物名称' ></el-table-column>
        <el-table-column prop='driver' label='司机' ></el-table-column>
        <el-table-column prop='carModel' label='车型' width="120"></el-table-column>
        <el-table-column prop='carNo' label='车牌号' ></el-table-column>
        <el-table-column prop='amount' label='开票金额' ></el-table-column>
        <el-table-column prop='mobile' label='联系电话' width="100"></el-table-column>
        <el-table-column prop='paymentAmount' label='司机收款金额' width="110"></el-table-column>
        <el-table-column prop='billDate' label='日期' width="140"></el-table-column>
      </el-table>
    </div>

    <div style="margin-top: 20px">
      <h3>附属文件</h3>
      <div v-for="f in log.fileList" :key="f.xh">
        <el-link  :href="f.fileurl" target="_blank" type="primary">{{f.filename}}</el-link>
      </div>
    </div>

    <div style="margin-top: 20px" v-if="log.status>=1">
      <el-link :href="log.url" target="_blank" type="success">金融平台链接</el-link>
    </div>

    <div style="margin-top: 20px" v-if="log.danbaoLogTime">
      <el-descriptions title="担保费反馈"  border >
        <el-descriptions-item label="确认时间">{{ log.danbaoLogTime }}</el-descriptions-item>
        <el-descriptions-item label="收款账号">{{ log.accountNum }}</el-descriptions-item>
        <el-descriptions-item label="收款开户行">{{ log.openBank }}</el-descriptions-item>
        <el-descriptions-item label="8位对账码">{{ log.reconciliationCode }}</el-descriptions-item>
        <el-descriptions-item label="担保费率(%)">{{ log.rate * 100 }}</el-descriptions-item>
        <el-descriptions-item label="担保费(元)">{{ log.fee }}</el-descriptions-item>
      </el-descriptions>
    </div>

    <div style="margin-top: 20px" v-if="log.fangkuanLogTime">
      <el-descriptions title="放款反馈"  border >
        <el-descriptions-item label="实际放款银行">{{ log.loanBankName }}</el-descriptions-item>
        <el-descriptions-item label="实际放款金额(元)">{{ log.loanAmtTotal }}</el-descriptions-item>
        <el-descriptions-item label="放款笔数">{{ log.loanList.length }}</el-descriptions-item>
      </el-descriptions>
    </div>
  </div>
</template>

<script>
export default {
  name: "LogDetail",
  props: ['log'],
  data:function (){
    return{

    }
  },
  mounted() {
    console.log("@@@")
  }
}
</script>

<style scoped>

</style>
