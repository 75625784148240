<template>
  <div class="pageBody">
      <div class="companyInfo partBody" >
        <h2 >1. 检查公司信息</h2>
        <hr >
        <el-descriptions class="top10" border style="padding: 5px">
          <el-descriptions-item label="公司名称">{{ userInfo.unitName }}</el-descriptions-item>
          <el-descriptions-item label="统一社会代码">{{ userInfo.dutyNumber }}</el-descriptions-item>
          <el-descriptions-item label="公司法人">{{ userInfo.legalPersonName }}</el-descriptions-item>
          <el-descriptions-item label="法人身份证">{{ userInfo.legalPersonIdentity }}</el-descriptions-item>
          <el-descriptions-item label="法人手机号码">{{ userInfo.legalPersonMobile }}</el-descriptions-item>
          <el-descriptions-item label="公司通讯地址">{{ userInfo.unitAddress }}</el-descriptions-item>
          <el-descriptions-item label="公司注册地址">{{ userInfo.unitRegisterAddress }}</el-descriptions-item>
          <el-descriptions-item label="收款账号开户行">{{ userInfo.payeeBankName }}</el-descriptions-item>
          <el-descriptions-item label="收款账号">{{ userInfo.payeeBankAccount }}</el-descriptions-item>
          <el-descriptions-item label="公司座机">{{ userInfo.unitTel }}</el-descriptions-item>
        </el-descriptions>
        <el-alert type="warning"  close-text="知道了"
                  title="请确保所填写信息的真实性，法人手机号是以法人身份证注册，否则会影响后续个人认证的操作，导致申请失败"></el-alert>

      </div>
      <div class="contract partBody">
        <h2 >2. 选择我的承接</h2>
        <hr >
        <div class="top10">
          <span class="describe" >选择我的承接:</span>
          <el-select v-model="myApplyForm.acceptId" placeholder="请选择" @change="getStatementById" style="width: 250px">
            <el-option
                v-for="item in myAccept"
                :key="item.id"
                :label="item.keyName"
                :value="item.id">
            </el-option>
          </el-select>
        </div>

      </div>

      <div class="amount partBody top10">
        <h2 >3. 申请金额
<!--          <span class="el-icon-question" @mouseover="questionShow = true" @mouseleave="questionShow = false">帮助</span>-->
        </h2>
        <hr >
        <div style="display: flex">
          <el-form class="top10" ref="applyForm" :model="myApplyForm" label-width="80px">
            <!--          <el-form-item label="账单金额(元):">-->
            <!--            <span style="margin-left: 15px;width: 205px" :style="myApplyForm.amount>totalMoney?{color : 'red'}:{}">{{totalMoney}}</span>-->
            <!--          </el-form-item>-->
            <el-form-item label="申请金额:">
              <el-input style="width: 205px;margin-left: 15px" v-model="myApplyForm.applyAmount" size="small">
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
            <el-form-item label="质押金额:">
              <el-input style="width: 205px;margin-left: 15px" v-model="myApplyForm.pledgeAmount" size="small">
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
            <el-form-item label="申请期限:">
              <el-input style="width: 205px;margin-left: 15px" disabled v-model="myApplyForm.limit" size="small">
                <template slot="append">日</template>
              </el-input>
            </el-form-item>
          </el-form>
<!--          <div v-show="questionShow" style="margin-left: 30px">-->
<!--            <el-image style="position: absolute;height: 200px" :src="ImageSrc" fit="contain"></el-image>-->
<!--          </div>-->
        </div>
        <el-alert type="warning"  close-text="知道了"
                  title="具体金额请咨询泷通相关业务人员"></el-alert>

      </div>

      <div class="partBody">
        <el-button @click="handleCommit"
            style="margin-left: 15px;margin-top: 15px" type='primary'>提交</el-button>
      </div>
  </div>
</template>

<script>
import ImageSrc from "@/assets/images/1200.jpg";
export default {
  name: "FinanceApply",
  data:function (){
    return{
      form:[],
      ImageSrc: ImageSrc,
      userInfo: '',
      myAccept: [],
      currentStatements: [],
      myApplyForm:{
        acceptId: '',
        applyAmount: '',
        pledgeAmount: '',
        limit: 90,
        // flowIds: []
      },
      totalMoney: 0,
      questionShow: false
    }
  },
  mounted() {
    this.initUserInfo()
    this.initAccept()
  },
  methods: {
    // countSelect(selection){
    //   this.totalMoney = 0
    //   let selected = this.$refs.form1.selection;
    //   for (const select of selected) {
    //     this.totalMoney += select.amount
    //   }
    // },
    initUserInfo(){
      this.HTTP.get('user/detail').then(res => {
        if (res.data.code == 1) {
          this.userInfo = res.data.data;
          console.log('@userInfo:',this.userInfo)
        } else {
          this.$message.error(res.data.message);
        }
      }).catch(err => {
        this.$message.error(err);
      })
    },
    initAccept(){
      this.HTTP.get('accept/initAccept').then(res => {
        if (res.data.code == 1) {
          this.myAccept = res.data.rows;
        } else {
          this.$message.error(res.data.message);
        }
      }).catch(err => {
        this.$message.error(err);
      })
    },
    checkSelectable(row){
      return row.hasApply === 0 && row.reckonStatus
    },
    getStatementById(){
      if (!this.myApplyForm.acceptId){
        this.$message.error("获取流水前请选择承接");
        return
      }
      this.HTTP.get('accept/getStatementById?id='+this.myApplyForm.acceptId).then(res => {
        if (res.data.code == 1) {
          this.currentStatements = res.data.rows;
        } else {
          this.$message.error(res.data.message);
        }
      }).catch(err => {
        this.$message.error(err);
      })
    },
    handleCommit(){
      let self = this
      // let selected = this.$refs.form1.selection;
      // for (const select of selected) {
      //   this.myApplyForm.flowIds.push(select.id)
      // }
      console.log('@myApplyForm:',this.myApplyForm)
      this.HTTP.post('/finance/financeApply',this.myApplyForm).then(res=>{
        if(res.data.success){
          this.$alert('点击确定转到我的申请', '提交成功', {
            confirmButtonText: '确定',
            callback: action => {
              this.$emit('exit',1)
            }
          });
        }else{
          this.$message.error(res.data.message);
        }
      }).catch(err=>{
        this.$message.error(err);
      })
    },

  }
}
</script>

<style scoped>
.describe{
  font-size: 14px;
  color: #606266;
  margin-left: 10px;
  margin-right: 20px
}
.top10{
  margin-top: 10px;
}
.pageBody{
  margin-right: 50px;
  margin-bottom: 50px;
}
.partBody{
  width: 100%;
}
.companyInfo{

}
.contract{

}
.project{

}
.amount{

}
.el-input.is-disabled >>> .el-input__inner{
  color: #3b3b3b;
}
</style>
