<template>
  <div>
    <!--搜索行-->
    <div>
      <el-form :inline='true' :model='query' class=''>
        <el-form-item label='状态'>
          <el-select v-model='query.status' placeholder='所有' clearable>
            <el-option label="已提交" value="0"></el-option>
            <el-option label="已通过泷通审核" value="1"></el-option>
            <el-option label="已通过金融平台审核" value="2"></el-option>
            <el-option label="金融平台已放款" value="3"></el-option>
            <el-option label="未通过泷通审核" value="-1"></el-option>
            <el-option label="未通过金融平台审核" value="-2"></el-option>
            <el-option label="放款失败" value="-3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type='primary' @click='init' icon='el-icon-search'>查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type='primary' @click='flushQuery' icon='el-icon-refresh-right'>重置</el-button>
        </el-form-item>
      </el-form>
    </div>

    <!--操作行-->
    <div class='button-line'>
      <el-button type='primary' @click='handleShowDetail' icon='el-icon-more-outline'>详情</el-button>
      <el-button type='success' @click='applyShow=true' icon='el-icon-s-finance'>发起申请</el-button>
      <el-badge value="需补充" :hidden="isNoticeUpdate" type="danger  " style="margin-left: 10px">
        <el-button type='info' @click='annualShow=true' icon='el-icon-s-marketing'>上年度财务状况</el-button>
      </el-badge>

    </div>

    <!--主列表-->
    <el-table :data='list' style='' @row-click='handleSelect' highlight-current-row>
      <el-table-column prop='applyNo' label='申请流水号' width="160"></el-table-column>
      <el-table-column prop='status' label='申请状态' width="130">
        <template slot-scope="scope">
          <span v-if="scope.row.status==0">已提交</span>
          <span v-if="scope.row.status==1">已通过泷通审核</span>
          <span v-if="scope.row.status==2">已通过金融平台审核</span>
          <span v-if="scope.row.status==3">金融平台已放款</span>
          <span v-if="scope.row.status==-1">未通过泷通审核</span>
          <span v-if="scope.row.status==-2">未通过金融平台审核</span>
          <span v-if="scope.row.status==-3">放款失败</span>
        </template>
      </el-table-column>
      <el-table-column prop='platform' label='放款方' width="100">
        <template slot-scope="scope">
          <span v-if="scope.row.platform===1">三峡</span>
        </template>
      </el-table-column>
      <el-table-column prop='applyAmount' label='申请金额(元)' width="100"></el-table-column>
      <el-table-column prop='timeLimit' label='申请期限(天)' width="100"></el-table-column>
      <el-table-column prop='acceptName' label='关联项目' width="200"></el-table-column>
      <el-table-column prop='reason' label='反馈'  min-width="100">
        <template slot-scope="scope">
          <span v-if="!scope.row.reason">-</span>
          <span v-if="scope.row.reason">{{scope.row.reason}}</span>
        </template>
      </el-table-column>

    </el-table>

    <!--底栏-->
    <div class='page-line top15' >
      <el-pagination background layout='sizes,total, prev, pager, next'
                     :page-sizes='pageSize'
                     @size-change='sizeChange'
                     :current-page='query.pageIndex' :page-size='query.pageRows'
                     :total='total' @current-change='pageChange'
                     prev-click='pageChangeOne(1)'
                     next-click='pageChangeOne(2)'>
      </el-pagination>
    </div>

    <el-dialog  title="详情" :visible.sync="detailShow" width="80%">
      <LogDetail :log="row"></LogDetail>
    </el-dialog>

    <el-dialog  title="发起申请" :visible.sync="applyShow" :destroy-on-close="true" :close-on-click-modal="false" width="850px">
      <FinanceApply @exit="doExit"></FinanceApply>
    </el-dialog>

    <el-dialog  title="上年度财务状况" :visible.sync="annualShow" v-if="annualShow" width="320px">
      <el-form ref="annualForm" :model="annualData" label-width="80px" label-position="left">
        <el-form-item label="截止日期">
          {{lastYearDay}}
        </el-form-item>
        <el-form-item label="总资产">
          <el-input v-model="annualData.totalAssets">
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
        <el-form-item label="总收入">
          <el-input v-model="annualData.income">
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
        <el-form-item label="员工数">
          <el-input v-model="annualData.employeNum">
            <template slot="append">人</template>
          </el-input>
        </el-form-item>
        <el-form-item label="负债率">
          <el-input v-model="annualData.debtRatio">
            <template slot="append">%</template>
          </el-input>
        </el-form-item>
        <el-form-item label="净利润">
          <el-input v-model="annualData.netProfit">
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
      </el-form>
      <el-button type='primary' @click='updateLastAnnual' icon=''>提交</el-button>
    </el-dialog>
  </div>
</template>

<script>
import crud from '@/util/mixin-crud';
import LogDetail from "@/components/common/center/finance/LogDetail";
import FinanceApply from "@/components/common/center/finance/FinanceApply";
import http from "@/util/http";
import dayjs from 'dayjs'

export default {
  name: "MyApplyLog",
  mixins: [crud],
  components:{
    LogDetail,FinanceApply
  },
  data:function (){
    return{
      title: '我的申请',
      url: '/finance',
      row: '',
      form: {},
      rules: {
      },
      detailShow: false,
      applyShow: false,
      annualShow: false,
      annualData: {},
      lastYearDay: new dayjs().subtract(1, "year").endOf("year").format("YYYY-MM-DD")
    }
  },
  computed:{
    //是否提示更新
    isNoticeUpdate(){
      if (this.annualData==null){
        return false
      }else {
        let lastYearDay = new dayjs(this.lastYearDay);
        let closeDay = new dayjs(this.annualData.closeDate);
        return closeDay.isSame(lastYearDay);
      }
    }
  },
  methods: {
    //点击某一行
    handleSelect(row, column, event) {
      this.row = row;
    },
    handleShowDetail(){
      if (!this.row || !this.row.applyNo) {
        this.notify('必须选择一行', 'info');
        return;
      }
      this.detailShow = true
    },
    beforeInit() {
      this.row = ''
      return true
    },
    doExit(isExit){
      if (isExit){
        this.applyShow = false
        this.flushQuery()
      }
    },

    //获取上年财务数据
    getLastAnnual() {
      http.get('/finance/lastAnnual')
          .then(response => {
            if (response.data.success) {
              if (response.data.data!=null){
                this.annualData = response.data.data
              }
            }else {
              this.$notify({
                title: response.data.message,
                type: 'error',
                duration: 2500
              })
            }
          }).catch(err => {
            console.log(err)
      })
    },
    //更新
    updateLastAnnual() {
      this.annualData.closeDate = this.lastYearDay
      http.post('/finance/updateAnnual', this.annualData)
          .then(response => {
            if (response.data.success) {
              this.$notify({
                title: response.data.data,
                type: 'success',
                duration: 2500
              })
              this.annualShow = false
              this.getLastAnnual()
            } else {
              this.$notify({
                title: response.data.message,
                type: 'error',
                duration: 2500
              })
            }
          }).catch(err => {
        console.log(err)
      })
    }


  },
  mounted() {
    this.init()
    this.getLastAnnual()
  }
}
</script>

<style scoped>
.top15{
  margin-top: 15px;
}

.button-line {
  margin-top: 0;
  margin-bottom: 15px;
}

.page-line {
  margin-top: 10px;
  margin-bottom: 5px;
}
</style>
